import commonGlobals from '@opendoor/shared-fe/build/commonGlobals';

export const DEPLOY_ENV = (process.env.NEXT_PUBLIC_DEPLOY_ENV ||
  'development') as keyof typeof commonGlobals;

const secrets = commonGlobals[DEPLOY_ENV];

export const XSRF_COOKIE_KEY = DEPLOY_ENV !== 'development' ? 'XSRF-G-TOKEN' : 'XSRF-TOKEN';
export const CONSUMER_FE_URL = secrets.consumerFeUrl;
export const GOOGLE_CLIENT_ID = secrets.googleClientID;
export const GOOGLE_MAPS_API_KEY = secrets.googleMaps;
export const SEGMENT_MARKET_JS_KEY = secrets.segment;
export const RAILS_URL = secrets.railsUrl;
export const ADMIN_URL = secrets.adminUrl;
export const REDIRECT_URLS_REGEX = secrets.redirectURLsRegex;
export const PARTNERSHIP_BFF_URL = secrets.partnerBffUrl;

export const DATADOG_RUM_APPLICATION_ID = 'a317f46d-d080-42c8-967f-147a7dfd8863';
export const DATADOG_RUM_APPLICATION_CLIENT_TOKEN = 'pub5745c1085abb698bce5cfc45d4c737ba';

export const FACEBOOK_APP_ID = 1662842977359779;

export const MITEK_LICENSE =
  'eyJzaWduYXR1cmUiOiJFT2xoaURUK1FRL3VPL3hqTTE5NFBySFYwWnBYeVRLT3BqMDFZcHRQWUN4UDRpSHhzSlo4QlZ5N3k5NlNURjFYRnQrL1MyNkxWZEdEa1dmQTdGemFlZkhMQm9pY0tING45VXZ3bXQ2cjVaaVdRZHN0dW9mZVVCWjVOK2syZXNlRCtzcGxNYzhLWE03aU9NYmxPSHVNdW8veUtMMzdlWU45ZzFIOG1UdGVxUGpkRkRrQmZ2bEVGVmdZNG05dlpwVS9Ccmx6dHBFVDZabzhZcTd0STJqbFVuRm5IUjROcTlkcGNaUDZYWWVseWJEczRqSkh6b2FueUFmS0hMTzUrRzM2TmVWV0FINGFpM3BlNmRUb2hQLzNEWWtFMEpocy92Rnk3bWUzS3FPYXVrajhmNWJUbVpPdVN1TjJIK016dUFYcys0WmVuMERuOFNMTmxpa3JnZzJxTWc9PSIsInNpZ25lZCI6eyJleHBpcnkiOiIyMDI0LTA5LTA0IiwiZmVhdHVyZXMiOlsiYmFyY29kZSIsImlkIl0sImFwcGxpY2F0aW9uSWRlbnRpZmllciI6eyJ3ZWIiOlsiKi5vcGVuZG9vci5jb20iLCJvcGVuZG9vci5jb20iLCIqLnNpbXBsZXJzZWxsLmNvbSIsInNpbXBsZXJzZWxsLmNvbSJdfSwiZ3BvIjoiMTZkIiwib3JnYW5pemF0aW9uIjoiTWl0ZWsgU3lzdGVtcyBJbmMuIiwidmVyc2lvbiI6IjEuMCJ9LCJiYXJjb2RlV2ViTGljZW5zZSI6IlZiVTAxN21GcEVYUFJGWmMrN3pwLzRUL01NbkJqZ0xxakNMYUdQMEZrcXJqUmlGam5JM2tNV3V1d3hQQXQvWHpGQXZvTCt0RXVYYTN3amNYdjNOdEZ5bU5BVTVsZllPTlcxdEFhM09pVFBvdzZtZmJJT0tsNDBwNmluNktGNm9RSVcwdWg5UElublRlYVpQUGFteCs5MFJhN1NJZC9rMHZCdjZDdFM5S1Axd0FjZDhCVEZYTlhFUHhudkVMSDN3WFJIQWNReW9odU0wMHMrbCtMQ3dJNzRFVHR0cEFpNTE2MTBXUmlja3NMZlFkNEdRK1ZucVNnZkJyRCtBb0tDOVBrd2w3WVp2Qkpxakk0UnBrOENiem1uKzVNOHZoN3FneGp3S0Q1VmdYK0wzaXNmT0hhNW5qckNSc3B6NS9ESDdpanF3YitJbUNacy9OMDk1cGk4M0Z4ODBkMEU1cHlPWVM3M1psV0R4NC9LWHZtUHloVy9EbDBzMi96WmZ2Zld1ZVMwSHltSVFJS0NUQzNZaldCU2JhWjZ5clpSdzI2U3cwUmt4ZE40VjdvWUJHTThJWEFDY0IzTktLaExTc3NmSy9OMHltVVlNd2pJT1M0c28rY29uWE9WOW45ZGUzdHpYcjErTnhwQ2ZBQ2YrTkhMMmF5TURVY21rYjZCSWM0dURQIn0';

// Hit staging Athena by default in development
//
// To hit local Athena invert the comment & uncommented `development` keys
const ATHENA_URLS = {
  production: 'https://graphql.managed.services.opendoor.com/api/graphql',
  // development: 'http://localhost:4000/api/graphql',
  development: 'https://graphql.simplersell.com/api/graphql',
  // development: 'http://athena-api-production-http-vpn.managed.services.opendoor.com/api/graphql',
  qa: 'https://graphql.simplersell.com/api/graphql',
  staging: 'https://graphql.simplersell.com/api/graphql',
  test: 'https://graphql.simplersell.com/api/graphql',
};
export const ATHENA_URL = ATHENA_URLS[DEPLOY_ENV];

// Cosmos URL
export const COSMOS_URLS = {
  production: 'https://www.opendoor.com',
  staging: 'https://demo.simplersell.com',
  qa: 'https://qa.simplersell.com',
  test: 'http://localhost:3011',
  development: 'http://localhost:3011',
};

export const COSMOS_URL = COSMOS_URLS[DEPLOY_ENV];

export const CHITCHATPROMPT_URL =
  DEPLOY_ENV === 'production'
    ? 'https://chitchatprompt-production.managed.services.opendoor.com'
    : 'https://chitchatprompt-staging.managed.services.opendoor.com';

// Hit qa Ola by default in development
//
// To hit local Ola invert the comment & uncommented `development` keys
const BUYER_HOSTS = {
  production: 'https://buy.opendoor.com',
  // development: 'http://localhost:3000',
  development: 'https://staging-buy.simplersell.com',
  qa: 'https://staging-buy.simplersell.com',
  staging: 'https://staging-buy.simplersell.com',
  test: 'https://staging-buy.simplersell.com',
};

// Consumer URLs
const CONSUMER_URLS = {
  production: 'https://consumer.opendoor.com',
  development: 'https://consumer.simplersell.com',
  qa: 'https://consumer.simplersell.com',
  staging: 'https://consumer.simplersell.com',
  test: 'https://consumer.simplersell.com',
};

export const CONSUMER_URL = CONSUMER_URLS[DEPLOY_ENV];

export const BUYER_HOST = BUYER_HOSTS[DEPLOY_ENV];

/*
 * Hit staging Rails by default in development
 * To hit local Web backend invert the comment & uncommented `development` keys
 */
const EXCLUSIVE_RAILS_HOSTS = {
  production: 'https://www.opendoor.com',
  // development: 'http://localhost:5006',
  // development: 'https://www.opendoor.com',
  development: 'https://demo.simplersell.com',
  qa: 'https://demo.simplersell.com',
  staging: 'https://demo.simplersell.com',
  test: 'https://demo.simplersell.com',
};

export const EXCLUSIVE_RAILS_HOST = EXCLUSIVE_RAILS_HOSTS[DEPLOY_ENV];

export const DESKTOP_HERO_URL = '1afd9b4404c54cd5bd4d3737eec0d70d.jpg';
export const MOBILE_HERO_URL = '9148e8b338c3434299dc44eaf850f072.jpg';
export const HERO_IMAGES = [
  { url: MOBILE_HERO_URL, media: '(max-width: 767px)' },
  { url: DESKTOP_HERO_URL, media: '(min-width: 768px)' },
];

// TODO: consolidation
export type TrackingTaxonomy =
  | 'buy_and_sell_hero_cta'
  | 'new_homepage'
  | 'buy_and_sell_sticky_cta'
  | 'complete_landing'
  | 'exclusive_offers_landing'
  | 'list_with_certainty'
  | 'buy_plus_list_hero_cta'
  | 'sell_homepage'
  | 'guessing_game'
  | 'lwc-buyer-fallthrough-protection-pilot'
  | 'address_entry_page';

export type AddressSearchLocation =
  | 'hero-cta'
  | 'cta-standalone'
  | 'basic-page-header'
  | 'header-standalone'
  | 'address-entry-page'
  | 'address-entry-mobile-page'
  | 'sticky-cta'
  | 'buy-and-sell-hero'
  | 'aerial-image-cta';

export const UUID_REGEX =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;
