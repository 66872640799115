import ReactModal from 'react-modal';

import { BricksProvider } from '@opendoor/bricks-next';
import { ThemedLite } from '@opendoor/bricks/theme/ODThemeLite';
import { AddContextualData, analytics, globalObservability } from '@opendoor/observability/slim';
import { NextPageContext } from 'next';
import App, { AppContext } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';

import ErrorBoundaryWithObservability from 'components/ErrorBoundary';
import NavListener from 'components/NavListener';

import {
  GlobalObservabilityContext,
  initObservability,
  OBSERVABILITY_QUERY_PARAM_KEYS,
  useObservability,
} from 'helpers/observability';

import AuthProvider from '../components/account/AuthContextProvider';
import { AddressValidationContextProvider } from '../helpers/AddressValidationContext';
import { APPTOOLBOX_FE_INJECTION_SCRIPT } from '../helpers/apptoolboxInjection';

if (process.env.OUTPUT_BRICKS_CSS) {
  require('../../public/bricks.css');
}
if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'test' && typeof window == 'undefined') {
  require('../../mockTivoServer.ts');
}

interface IProps extends NextPageContext {
  /**  path of HTTP header x-od-forwarded-url */
  originPath: string;
  /** HTTP header x-od-forwarded-url */
  originUrl: string;
  /** HTTP header referer */
  referer: string;
  /* path in cosmos */
  cosmosPathname: string;
}
interface IErrorState {
  hasError?: boolean;
  errorEventId?: string;
}

export default class ErrorApp extends App<IProps, IErrorState> {
  state: IErrorState = {
    hasError: false,
  };

  static async getInitialProps({ Component, ctx }: AppContext) {
    const originUrl = (ctx.req?.headers['x-od-forwarded-url'] as string) ?? '';
    let originPath: string;
    try {
      originPath = new URL(originUrl).pathname;
    } catch {
      originPath = '';
    }

    const referer = (ctx.req?.headers['referer'] as string) ?? '';

    let pageProps = {};
    try {
      if (Component.getInitialProps) {
        pageProps = await Component.getInitialProps(ctx);
      }
      return {
        pageProps,
        originPath,
        originUrl,
        referer,
        cosmosPathname: ctx.pathname,
      };
    } catch (error) {
      const { captureException } = await import('@sentry/nextjs');
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = captureException(error);
      return {
        hasError: true,
        errorEventId,
        pageProps,
        originUrl,
        originPath,
        referer,
      };
    }
  }

  static getDerivedStateFromProps(props: IErrorState, state: IErrorState) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }
  async componentDidCatch(error: any) {
    const errorEventId = globalObservability.getSentryClient().captureException?.(error);
    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  componentDidMount() {
    const { originPath, originUrl, referer, cosmosPathname } = this.props;

    const globalContext: GlobalObservabilityContext = [
      {
        key: 'cosmosPathname',
        value: cosmosPathname,
      },
    ];

    // For allowed query parameters, let's add them to our global observability context
    Object.keys(this.props.router.query)?.forEach((key) => {
      const queryKey = toCamelCase(key);
      const value = this.props.router.query[key];
      if (OBSERVABILITY_QUERY_PARAM_KEYS.includes(queryKey) && value !== undefined) {
        globalContext.push({
          key: queryKey,
          value: `${value}`,
        });
      }
    });
    initObservability({ globalContext });
    // TODO: Remove once product teams have migrated to the Journey-Production
    // `page_view_event` auto-tracked via cloudflare-worker
    analytics.trackPageView({
      originPath,
      originUrl,
      referer,
      resource: 'cosmos',
    });
  }

  render() {
    // Necessary for accessibility. ReactModal uses this to hide (via aria-hidden) the rest of the
    // app while the modal is active.
    // https://reactcommunity.org/react-modal/accessibility/#app-element
    ReactModal.setAppElement('#__next');

    // right now, we're not doing anything with the error. We might want to redirect
    // to /error in the future (or reload the page).
    const { Component, pageProps, originPath, originUrl, referer, err } = this.props;
    // @ts-expect-error: components can set a custom theme on their default page export. Overriding nextjs type to reflect this is overly complicated
    const theme = Component.theme || 'legacy';
    return (
      <AddContextualData data={{ originPath, originUrl, referer }}>
        {/*
        TODO @growth - migrate cosmos onto eero breakpoints
        Breakpoints were re-designated by most common device usage
        https://www.figma.com/file/ZJh80b2xog1piMI07F1dRB/Hub-MVP---2021?node-id=2433%3A37468
        */}
        <ThemedLite theme={theme}>
          <Head>
            <meta
              key="description"
              name="description"
              content="Opendoor is the new way to sell your home. Skip the hassle of listing, showings and months of stress, and close on your own timeline. Get a free offer today!"
            />
            <meta key="og:url" property="og:url" content="https://opendoor.com" />
            <meta key="og:type" property="og:type" content="website" />
            <meta
              key="og:title"
              property="og:title"
              content="Opendoor | Sell your home the minute you're ready."
            />
            <meta
              key="og:description"
              property="og:description"
              content="Opendoor is the new way to sell your home. Skip the hassle of listing, showings and months of stress, and close on your own timeline. Get a free offer today!"
            />
            <meta
              key="og:image"
              property="og:image"
              content="https://images.opendoor.com/source/s3/imgdrop-production/2024-06-1719377665829-19976.jpg?preset=square-1024&service=cosmos&dpr=2"
            />
            <meta key="twitter:card" name="twitter:card" content="summary_large_image" />
            <meta key="twitter:domain" property="twitter:domain" content="opendoor.com" />
            <meta key="twitter:url" property="twitter:url" content="https://opendoor.com" />
            <meta
              key="twitter:title"
              name="twitter:title"
              content="Opendoor | Sell your home the minute you're ready."
            />
            <meta
              key="twitter:description"
              name="twitter:description"
              content="Opendoor is the new way to sell your home. Skip the hassle of listing, showings and months of stress, and close on your own timeline. Get a free offer today!"
            />
            <meta
              key="twitter:image"
              name="twitter:image"
              content="https://images.opendoor.com/source/s3/imgdrop-production/4c68636ad1df40d08a114638883c30d0.png?preset=square-2048"
            />
          </Head>
          {/* eslint-disable-next-line @next/next/inline-script-id */}
          <Script
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{ __html: APPTOOLBOX_FE_INJECTION_SCRIPT }}
          />
          <div id="x-od-app-toolbox-root"></div>
          <ErrorBoundaryWithObservability>
            <NavListener />
            <AuthProvider>
              <BricksProvider useObservability={useObservability}>
                <AddressValidationContextProvider>
                  <Component {...pageProps} err={err} />
                </AddressValidationContextProvider>
              </BricksProvider>
            </AuthProvider>
          </ErrorBoundaryWithObservability>
        </ThemedLite>
      </AddContextualData>
    );
  }
}

function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}
